import { removeJwtToken } from "@/cookies/jwtCookieHelper"
import { removeJwtGovToken, removeStateGov } from "@/cookies/jwtGovCookieHelper"
import { useCurrentProfileStore } from "@/stores/data/useCurrentProfileStore"
import { isDevelopmentMode } from "@/utils/environmentUtils"

export function logoutService(
  navigate: (path: string) => void,
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | null>>,
) {
  const { reset } = useCurrentProfileStore.getState()

  reset()
  removeJwtToken()
  removeJwtGovToken()
  removeStateGov()
  localStorage.clear()
  sessionStorage.clear()
  setIsLoggedIn(false)

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.active?.postMessage("clear-cache")
    })
  }

  const paths = ["/"]
  const domains = [
    "",
    "localhost",
    "msqualifica.com.br",
    "msqualifica.ms.gov.br",
    "hom.msqualifica.ms.gov.br",
  ]

  paths.forEach((path) => {
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=")
      const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim()
      domains.forEach((domain) => {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path};domain=${domain}`
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path}`
      })
    })
  })

  if (!isDevelopmentMode()) {
    const redirectUri = `https://${process.env.NEXT_PUBLIC_BASE_URL?.includes("hom") ? "hom." : ""}msqualifica.ms.gov.br/`
    const clientId = process.env.NEXT_PUBLIC_AUTH_GOV_CLIENT_ID
    const govRedirect = process.env.NEXT_PUBLIC_AUTH_GOV_REDIRECT
    window.location.href = `${govRedirect}/sso/sair?client_id=${clientId}&redirect_uri=${redirectUri}`
  } else {
    navigate("/")
  }
}
