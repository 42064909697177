import Cookies from "js-cookie"

const GOV_TOKEN_KEY = "jwt_gov_token"
const STATE_GOV = "state_gov"

export const setJwtGovToken = (token: string) => {
  Cookies.set(GOV_TOKEN_KEY, token)
}

export const getJwtGovToken = (): string | undefined => {
  return Cookies.get(GOV_TOKEN_KEY)
}

export const removeJwtGovToken = () => {
  Cookies.remove(GOV_TOKEN_KEY)
}

export const removeStateGov = () => {
  Cookies.remove(STATE_GOV)
}
